import { Box, Button, Card, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import OldMan from "../../assets/about-us.jpg";
import { FaArrowCircleRight } from "react-icons/fa";
import "animate.css";
import { useInView } from "../../hooks";

const AboutUs = () => {
  const { ref, isInView } = useInView({ threshold: 0.1 });
  return (
    <Box sx={{ width: "100%" }}>
      <Grid container sx={{ display: "flex", alignItems: "center" }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          className={`animate__animated ${
            isInView ? "animate__slideInLeft" : ""
          }`}
        >
          <Stack>
            <Typography
              sx={{
                fontSize: "1.3em",
                textAlign: "left",
                color: "primary.main",
                fontWeight: "500",
              }}
            >
              ABOUT US
            </Typography>
            <Typography
              sx={{
                fontSize: {
                  lg: "2.5em",
                  md: "1.5em",
                  sm: "1.5em",
                  xs: "1em",
                },
                textAlign: "left",
                fontFamily: "Candal, sans-serif;",
                fontWeight: "400",
                fontStyle: "normal",
                paddingBottom: "1rem",
              }}
            >
              <span style={{ color: "#232941" }}>KENT AND </span>
              <span style={{ color: "#26AAE0" }}>PARTNERS CARE</span>
            </Typography>
            <Typography
              sx={{
                fontSize: {
                  lg: "1.2em",
                  md: "0.7em",
                  sm: "0.7em",
                  xs: "0.7em",
                },
                textAlign: "left",
              }}
            >
              Welcome to Kent and Partners Care Home, where compassionate care
              meets exceptional service. We are dedicated to enhancing the
              quality of life for the elderly and mobility-impaired in our
              community. Our comprehensive homecare services ensure that you or
              your loved ones receive personalized, professional care either in
              the comfort of your own home or in our care facility. Experience
              the peace of mind that comes with knowing you are in the best
              hands.
            </Typography>
            <Grid
              sx={{
                paddingTop: "2rem",
                display: "flex",
                justifyContent: {
                  lg: "flex-start",
                  md: "flex-start",
                  sm: "flex-start",
                  xs: "flex-start",
                },
                paddingBottom: "2rem",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  borderRadius: "20px",
                  width: "150px",
                  color: "background.paper",
                  marginRight: "1rem",
                  "&:hover": {
                    backgroundColor: "secondary.main",
                  },
                }}
                endIcon={<FaArrowCircleRight color="#fffafa" />}
              >
                Read More
              </Button>
            </Grid>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          sx={{ display: "flex", justifyContent: "center" }}
          className={`animate__animated ${
            isInView ? "animate__slideInRight" : ""
          }`}
        >
          <Card
            sx={{
              //   backgroundImage: `url(${OldMan})`,
              //   backgroundRepeat: "no-repeat",
              //   objectFit:"cover",
              //   backgroundSize: "50% 70%",
              width: "70%",
              borderRadius: "20px",
              //   height: "100%",
            }}
          >
            <img
              src={OldMan}
              alt="old-man"
              style={{ objectFit: "cover", width: "100%", height: "100%" }}
            />
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AboutUs;
