import React from "react";
import ContactTopNav from "../TopNav/ContactTopNav";
import Navbar from "../TopNav/NavBar";
import Footer from "../Footer";
import { Box, Grid } from "@mui/material";

const ViewPort = ({ children }) => {
  return (
    <Box>
      <Grid
        sx={{ display: { lg: "flex", md: "none", sm: "none", xs: "none" } }}
      >
        <ContactTopNav />
      </Grid>
      <Navbar />
      {children}
      <Footer />
    </Box>
  );
};

export default ViewPort;
