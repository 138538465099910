import { Avatar, Box, Grid, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import XIcon from "@mui/icons-material/X";

const OurTeam = () => {
  return (
    <Box sx={{ width: "100%" }}>
      <Grid container sx={{ display: "flex", justifyContent: "center" }}>
        <Typography
          sx={{ fontSize: "1.3em", color: "primary.main", fontWeight: "500" }}
        >
          OUR TEAM
        </Typography>
      </Grid>

      <Grid
        container
        sx={{
          padding: "1rem 0 3rem 0",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: { lg: "3em", md: "2.5em", sm: "2em", xs: "1.5em" },
            textAlign: "center",
            width: { lg: "80%", md: "80%", sm: "100%", xs: "100%" },
            fontFamily: "Candal, sans-serif;",
            fontWeight: "400",
            fontStyle: "normal",
            color: "secondary.main",
          }}
        >
          Meet the <span style={{ color: "#26AAE0" }}>Amazing</span> Minds
          Behind the <span style={{ color: "#26AAE0" }}>Magic</span>
        </Typography>
      </Grid>
      <Grid
        container
        columnSpacing={3}
        rowSpacing={{ lg: 0, md: 3, sm: 3, xs: 3 }}
        sx={{ paddingTop: "3rem" }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={3}
          sx={{ paddingBottom: { lg: "0", md: "0", sm: "5rem", xs: "5rem" } }}
        >
          <Paper
            elevation={2}
            sx={{
              padding: "2rem",
              display: "flex",
              justifyContent: "center",
              borderRadius: "20px",
              height: "350px",
              "&:hover": {
                boxShadow: "-8px 15px 45px 0px rgba(139,211,227,0.20)",
              },
            }}
          >
            <Stack>
              <Grid
                sx={{
                  paddingBottom: "3rem",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "-5rem",
                  transition: "transform 1s ease",
                  "&:hover": {
                    transform: "rotate(180deg)",
                  },
                }}
              >
                <Avatar alt="staff-pic" sx={{ width: 150, height: 150 }} />
              </Grid>
              <Grid sx={{ paddingBottom: "2rem" }}>
                <Typography
                  sx={{
                    fontFamily: "Candal, sans-serif;",
                    fontWeight: "400",
                    fontStyle: "normal",
                    textAlign: "center",
                  }}
                >
                  Dr. Duke Igwilo
                </Typography>
              </Grid>
              <Grid>
                <Typography
                  sx={{
                    fontSize: "1em",
                    color: "primary.main",
                    fontWeight: "550",
                    textAlign: "center",
                  }}
                >
                  MD/CEO
                </Typography>
              </Grid>

              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  paddingTop: "3rem",
                }}
              >
                <Grid item xs={3} sm={3} md={3} lg={3}>
                  <FacebookOutlinedIcon
                    sx={{ color: "primary.main", fontSize: 25 }}
                  />
                </Grid>
                <Grid item xs={3} sm={3} md={3} lg={3}>
                  <InstagramIcon sx={{ color: "primary.main", fontSize: 25 }} />
                </Grid>
                <Grid item xs={3} sm={3} md={3} lg={3}>
                  <LinkedInIcon sx={{ color: "primary.main", fontSize: 25 }} />
                </Grid>
                <Grid item xs={3} sm={3} md={3} lg={3}>
                  <XIcon sx={{ color: "primary.main", fontSize: 25 }} />
                </Grid>
              </Grid>
            </Stack>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <Paper
            elevation={2}
            sx={{
              padding: "2rem",
              display: "flex",
              justifyContent: "center",
              borderRadius: "20px",
              height: "350px",
              "&:hover": {
                boxShadow: "-8px 15px 45px 0px rgba(139,211,227,0.20)",
              },
            }}
          >
            <Stack>
              <Grid
                sx={{
                  paddingBottom: "3rem",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "-5rem",
                  transition: "transform 1s ease",
                  "&:hover": {
                    transform: "rotate(180deg)",
                  },
                }}
              >
                <Avatar alt="staff-pic" sx={{ width: 150, height: 150 }} />
              </Grid>
              <Grid sx={{ paddingBottom: "2rem" }}>
                <Typography
                  sx={{
                    fontFamily: "Candal, sans-serif;",
                    fontWeight: "400",
                    fontStyle: "normal",
                    textAlign: "center",
                  }}
                >
                  Mrs. Beatrice Chinenye
                </Typography>
              </Grid>
              <Grid>
                <Typography
                  sx={{
                    fontSize: "1em",
                    color: "primary.main",
                    fontWeight: "550",
                    textAlign: "center",
                  }}
                >
                  Head , Corporate Services.
                </Typography>
              </Grid>

              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  paddingTop: "3rem",
                }}
              >
                <Grid item xs={3} sm={3} md={3} lg={3}>
                  <FacebookOutlinedIcon
                    sx={{ color: "primary.main", fontSize: 25 }}
                  />
                </Grid>
                <Grid item xs={3} sm={3} md={3} lg={3}>
                  <InstagramIcon sx={{ color: "primary.main", fontSize: 25 }} />
                </Grid>
                <Grid item xs={3} sm={3} md={3} lg={3}>
                  <LinkedInIcon sx={{ color: "primary.main", fontSize: 25 }} />
                </Grid>
                <Grid item xs={3} sm={3} md={3} lg={3}>
                  <XIcon sx={{ color: "primary.main", fontSize: 25 }} />
                </Grid>
              </Grid>
            </Stack>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OurTeam;
