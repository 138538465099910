import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import {
  MdOutlineLocationOn,
  MdOutlineMailOutline,
  MdOutlinePhoneInTalk,
} from "react-icons/md";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const ContactTopNav = () => {
  return (
    <Box sx={{ width: "100%" }}>
      <Grid
        container
        sx={{
          backgroundColor: "secondary.main",
          display: "flex",
          padding: "1rem 3rem 1rem 3rem",
          alignItems: "center",
          //   justifyContent: "space-between",
        }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={4}
          sx={{ display: "flex", alignItems: "flex-start" }}
        >
          <Grid sx={{ marginRight: "0.5rem" }}>
            <MdOutlineLocationOn color="#fff" />
          </Grid>
          <Grid>
            <Typography sx={{ color: "background.paper", fontSize: "0.9em" }}>
              FLAT 4, BLOCK B30 ZONE 3, GAMES VILLAGE, ABUJA.
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={3}
          sx={{ display: "flex", alignItems: "flex-start" }}
        >
          <Grid sx={{ marginRight: "0.5rem" }}>
            <MdOutlineMailOutline size={18} color="#fff" />
          </Grid>
          <Grid>
            <Typography sx={{ color: "background.paper", fontSize: "0.9em" }}>
              support@kentandpartners.com.ng
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={2}
          sx={{ display: "flex", alignItems: "flex-start" }}
        >
          <Grid sx={{ marginRight: "0.5rem" }}>
            <MdOutlinePhoneInTalk size={18} color="#fff" />
          </Grid>
          <Grid>
            <Typography sx={{ color: "background.paper", fontSize: "0.9em" }}>
              09052781116
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={3}
          sx={{ display: "flex", alignItems: "flex-start" }}
        >
          <Grid
            item
            container
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Typography sx={{ color: "background.paper", fontSize: "0.9em" }}>
              FOLLOW US:
            </Typography>
          </Grid>
          <Grid item container sx={{ display: "flex", alignItems: "center" }}>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <FaFacebookF size={15} color="#fff" />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <FaInstagram size={15} color="#fff" />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <FaXTwitter size={15} color="#fff" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactTopNav;
