import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import styles from "./Hero.module.css";
import HeroImg2 from "../../assets/heroimg-2.jpg";
import { FaArrowCircleRight } from "react-icons/fa";

const ContactUsSection = () => {
  return (
    <Box>
      <Grid container className={styles.imageContainer}>
        <img
          src={HeroImg2}
          alt="Background"
          className={styles.backgroundImage}
        />
        <Grid className={styles.overlay}>
          <Stack
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: { lg: "3em", md: "2.5em", sm: "2em", xs: "2em" },
                textAlign: "center",
                width: { lg: "90%", md: "80%", sm: "100%", xs: "100%" },
                fontFamily: "Candal, sans-serif;",
                fontWeight: "400",
                fontStyle: "normal",
                color: "background.paper",
              }}
            >
              Experience{" "}
              <span style={{ color: "#26AAE0" }}>compassionate care</span> and{" "}
              <span style={{ color: "#26AAE0" }}>companionship </span>
              in a home-like setting.
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "1.3em",
                paddingTop: "2rem",
                width:"80%"
              }}
            >
              Feel at home with our warm, friendly staff, state of the art
              facilities and a nurturing environment designed to enhance comfort
              and well-being.
            </Typography>
            <Grid
              sx={{
                display: "flex",
                alignItems: "center",
                paddingTop: "2rem",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  borderRadius: "20px",
                  //   width: "150px",
                  color: "background.paper",
                  backgroundColor: "primary.main",
                  "&:hover": {
                    backgroundColor: "secondary.main",
                  },
                }}
                endIcon={<FaArrowCircleRight color="#fffafa" />}
              >
                Contact Us Now
              </Button>
            </Grid>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactUsSection;
