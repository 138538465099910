import * as React from "react";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Button,
  MenuItem,
  Grid,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { FaArrowCircleRight } from "react-icons/fa";
import Logo from "../../../../assets/eldercare.png";

const NavBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  // const goToLoginPage = () => {
  //   router.push("/login");
  // };
  // const goToSignUpPage = () => {
  //   router.push("/signup");
  // };

  return (
    <AppBar
      position="sticky"
      sx={{
        boxShadow: "4px 4px 15px -8px rgba(0,0,0,0.89)",
      }}
    >
      <Container maxWidth="xl" sx={{ background: "#fff" }}>
        <Toolbar
          disableGutters
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "1rem",
            background: "#fff",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              cursor: "pointer",
            }}
          >
            <img
              src={Logo}
              alt="KENT"
              style={{
                width: 50,
                height: 50,
                margin: "-0.4rem 0.5rem 0 0",
              }}
            />
            <Typography
              sx={{
                fontSize: "1.5em",
                textAlign: "center",
                fontFamily: "Candal, sans-serif;",
                fontWeight: "500",
                fontStyle: "normal",
                color: "secondary.main",
              }}
            >
              KENT & <span style={{ color: "#26AAE0" }}>PARTNERS CARE</span>
            </Typography>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenUserMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem>
                <Typography textAlign="center" sx={{ fontWeight: "400" }}>
                  Home{" "}
                </Typography>
              </MenuItem>
              <MenuItem>
                <Typography textAlign="center" sx={{ fontWeight: "400" }}>
                  About{" "}
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleOpenNavMenu}>
                <Typography textAlign="center">
                  Services{" "}
                  <KeyboardArrowDownIcon sx={{ margin: "0 0 0 0.3rem" }} />
                </Typography>
              </MenuItem>

              <MenuItem>
                <Typography textAlign="center">Gallery </Typography>
              </MenuItem>

              <MenuItem>
                <Typography textAlign="center">Blog </Typography>
              </MenuItem>
              <MenuItem>
                <Typography textAlign="center">Contact </Typography>
              </MenuItem>

              <MenuItem>
                <Button
                  endIcon={<FaArrowCircleRight size={20} />}
                  sx={{
                    backgroundColor: "primary.main",
                    color: "background.paper",
                    width: "160px",
                    fontSize: "1em",
                    borderRadius: "30px",
                    "&:hover": {
                      backgroundColor: "secondary.main",
                    },
                  }}
                  // onClick={goToSignUpPage}
                >
                  Get Started
                </Button>
              </MenuItem>
            </Menu>
          </Box>

          <Box sx={{ display: { xs: "flex", md: "none" }, cursor: "pointer" }}>
            <img
              src={Logo}
              alt="KENT"
              style={{
                width: 30,
                height: 30,
                margin: "-0.2rem 0.5rem 0 0",
              }}
            />
            <Typography
              sx={{
                fontSize: {lg:"1em", md:"1em", sm:"1em", xs:"0.8em"},
                textAlign: "center",
                fontFamily: "Candal, sans-serif;",
                fontWeight: "500",
                fontStyle: "normal",
                color: "secondary.main",
              }}
            >
              KENT & <span style={{ color: "#26AAE0" }}>PARTNERS CARE</span>
            </Typography>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Grid>
              <Typography
                sx={{
                  my: 2,
                  color: "primary.main",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "1em",
                  cursor: "pointer",
                  margin: "0 1.5rem 0 0",
                  fontWeight: "400",
                  textDecoration: "none",
                }}
              >
                Home
              </Typography>
            </Grid>
            <Grid>
              <Typography
                sx={{
                  my: 2,
                  color: "primary.main",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "1em",
                  cursor: "pointer",
                  margin: "0 1.5rem 0 0",
                  fontWeight: "400",
                  textDecoration: "none",
                }}
              >
                About
              </Typography>
            </Grid>

            <Grid onClick={handleOpenNavMenu}>
              <Typography
                sx={{
                  my: 2,
                  color: "primary.main",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "1em",
                  cursor: "pointer",
                  margin: "0 1.5rem 0 0",
                  fontWeight: "400",
                }}
              >
                Services
                <KeyboardArrowDownIcon sx={{ margin: "0 0 0 0.3rem" }} />
              </Typography>
            </Grid>

            <Grid>
              <Typography
                sx={{
                  my: 2,
                  color: "primary.main",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "1em",
                  cursor: "pointer",
                  margin: "0 1.5rem 0 0",
                  fontWeight: "400",
                  textDecoration: "none",
                }}
              >
                Gallery
              </Typography>
            </Grid>

            <Grid>
              <Typography
                sx={{
                  my: 2,
                  color: "primary.main",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "1em",
                  cursor: "pointer",
                  margin: "0 1.5rem 0 0",
                  fontWeight: "400",
                  textDecoration: "none",
                }}
              >
                Blog
              </Typography>
            </Grid>

            <Grid>
              <Typography
                sx={{
                  my: 2,
                  color: "primary.main",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "1em",
                  cursor: "pointer",
                  margin: "0 1.5rem 0 0",
                  fontWeight: "400",
                  textDecoration: "none",
                }}
              >
                Contact us
              </Typography>
            </Grid>
            <Grid>
              <Button
                endIcon={<FaArrowCircleRight size={20} />}
                sx={{
                  backgroundColor: "primary.main",
                  borderRadius: "30px",
                  color: "background.paper",
                  width: "160px",
                  fontSize: "1em",
                  "&:hover": {
                    backgroundColor: "secondary.main",
                  },
                }}
              >
                Get Started
              </Button>
            </Grid>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default NavBar;
