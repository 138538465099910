import registered from "../assets/registered.png";
import caregiver from "../assets/caregiver.png";
import homecare from "../assets/homecare.png";
import flexibility from "../assets/flexibility.png";

export const whyChooseUseData = [
  {
    id: 0,
    pic: registered,
    title: "FULLY MANAGED AND REGISTERED",
    desc: "By choosing Kent and Partners Care you are opting for a full-service home care provider that will manage your support from start to finish. We are registered and licensed to provide this support. We handle everything, so you worry less. Our managers, caregivers and medical team are all certified and registered, hence giving you the confidence to relax and know you are in capable hands.",
  },
  {
    id: 1,
    pic: caregiver,
    title: "DEPENDABLE CAREGIVERS",
    desc: "Every single one of our caregivers are employed directly by Kent and Partners Care. We never enlist the use of staffing agencies. This means we can ensure the quality of service that each of our caregivers deliver meets our high standards and that they are each totally supported in their roles.",
  },
  {
    id: 2,
    pic: homecare,
    title: "HOMECARE SPECIALISTS",
    desc: "Our experience over the years has enabled us to gain vast knowledge in this industry. We are experienced in providing patient-centred care both in-house and in the comfort of your home with standard models of care and approach. This means we take into consideration the individuals and family’s needs and also the culture they are exposed to, to find the best way to offer care.",
  },
  {
    id: 3,
    pic: flexibility,
    title: "FLEXIBLE AND ADAPTABLE",
    desc: "Care and support needs can change over time – and sometimes quite suddenly – so we see the importance in providing a flexible and adaptable service. We will regularly review your support plan and carry out caregiver supervisions so we can respond to any changes and arrange extra training or support if it is needed.",
  },
];
