import React from "react";
import ViewPort from "../shared/ViewPort";
import { Box, Grid } from "@mui/material";
import Hero from "./Hero";
import AboutUs from "./AboutUs";
import Services from "./Services";
import ContactUsSection from "./ContactUsSection";
import WhyChooseUs from "./WhyChooseUs";
import OurTeam from "./OurTeam";

const HomePage = () => {
  return (
    <ViewPort>
      <Box>
        <Grid>
          {" "}
          <Hero />
        </Grid>
        <Grid sx={{ padding: "3rem" }}>
          <AboutUs />
        </Grid>
        <Grid sx={{ padding: "3rem" }}>
          <Services />
        </Grid>
        <Grid>
          <ContactUsSection />
        </Grid>
        <Grid sx={{ padding: {lg:"3rem", md:"3rem", sm:"2rem", xs:"1rem"} }}>
          <WhyChooseUs />
        </Grid>
        <Grid sx={{ padding: "3rem" }}>
          <OurTeam />
        </Grid>
      </Box>
    </ViewPort>
  );
};

export default HomePage;
