import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { serviceData } from "../../../helpers/serviceData";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import styles from "./services.module.css";

const Services = () => {
  return (
    <Box sx={{ width: "100%" }}>
      <Stack>
        <Grid container sx={{ display: "flex", justifyContent: "center" }}>
          <Typography
            sx={{
              color: "primary.main",
              fontSize: "1.3em",
              fontWeight: "500",
              textAlign: "center",
              textTransform: "uppercase",
            }}
          >
            Services
          </Typography>
        </Grid>
        <Grid
          container
          sx={{ display: "flex", justifyContent: "center", paddingTop: "1rem" }}
        >
          <Typography
            sx={{
              fontSize: { lg: "3em", md: "2em", sm: "2em", xs: "2em" },
              textAlign: "center",
              fontFamily: "Candal, sans-serif;",
              fontWeight: "400",
              fontStyle: "normal",
              color: "secondary.main",
              width: { lg: "60%", md: "80%", sm: "100%", xs: "100%" },
            }}
          >
            We Offer A{" "}
            <span style={{ color: "#26AAE0" }}>Comprehensive Range</span> Of
            Services
          </Typography>
        </Grid>
        <Grid
          container
          sx={{ display: "flex", justifyContent: "center", paddingTop: "2rem" }}
        >
          <Typography
            sx={{
              color: "black.main",
              fontSize: "1.2em",
              fontWeight: "400",
              textAlign: "center",
              width: { lg: "60%", md: "80%", sm: "80%", xs: "100%" },
            }}
          >
            We are committed to providing personalized care plans that are
            tailored to each resident&:apos unique needs and preferences,
            ensuring that they receive the support they need to live a happy and
            healthy life.
          </Typography>
        </Grid>

        <Grid
          container
          rowGap={8}
          sx={{
            paddingTop: "2rem",
            display: "flex",
            justifyContent: { md: "center", sm: "center", xs: "center" },
          }}
        >
          {serviceData.map((service) => {
            const { id, title, pic, content } = service;
            return (
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={4}
                key={id}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Card sx={{ maxWidth: 345, borderRadius: "15px" }}>
                  <CardMedia
                    component="img"
                    alt="care-one"
                    height="250"
                    image={pic}
                    sx={{
                      padding: "1rem",
                      borderRadius: "30px",
                      transition: "transform 0.3s ease",
                      "&:hover": {
                        transform: "scale(1.2)",
                      },
                    }}
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      sx={{ color: "secondary.main" }}
                    >
                      {title}
                    </Typography>
                    <Typography
                      color="black.main"
                      sx={{
                        fontSize: "1em",
                        width: { lg: "100%", md: "100%", sm: "80%", xs: "80%" },
                      }}
                      // className={styles.multilineTruncation}
                    >
                      {content}
                    </Typography>
                  </CardContent>
                  <CardActions sx={{ padding: "0 0 1rem 1rem" }}>
                    <Button
                      sx={{
                        width: "20px",
                        height: "65px",
                        borderRadius: "50%",
                        backgroundColor: "primary.main",
                        "&:hover": {
                          backgroundColor: "secondary.main",
                        },
                      }}
                    >
                      <ArrowForwardIcon
                        sx={{
                          color: "background.paper",
                          fontSize: 30,
                        }}
                      />
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Stack>
    </Box>
  );
};

export default Services;
