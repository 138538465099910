import HeroImg1 from "../assets/heroimg-1.jpg";
import HeroImg2 from "../assets/heroimg-2.jpg";
import HeroImg3 from "../assets/heroimg-3.jpg";

export const serviceData = [
  {
    id: 0,
    pic: HeroImg1,
    title: "Doctor Visits",
    content:
      "Our doctor visit service brings the expertise of a medical professional right to your doorstep. At your request, a qualified doctor will review your health status, recommend necessary diagnostics, develop a personalized treatment plan, and, if required, refer you to a specialist. Enjoy the convenience and comfort of receiving top-notch medical care at home.",
  },
  {
    id: 1,
    pic: HeroImg2,
    title: "Nursing Care",
    content:
      "Experience the highest standard of home nursing care with our team of expertly trained and registered nurses. Bringing certified expertise and extensive clinical knowledge, our nurses provide compassionate and professional care tailored to your unique needs. Whether you require post-operative care, chronic disease management, or general health support, our nurses are here to help.",
  },
  {
    id: 2,
    pic: HeroImg3,
    title: "Pharmaceuticals",
    content:
      "Managing medications has never been easier with our home pharmaceuticals service. We organize and package your medications neatly based on the time of day they should be taken, ensuring you never miss a dose. Let us handle the complexities of medication management, so you can focus on your health and well-being",
  },
  {
    id: 3,
    pic: HeroImg1,
    title: "Diagnostics",
    content:
      "Stay on top of your health with our convenient home diagnostics service. Our skilled nurses will collect samples, ensuring they are conveyed safely to the laboratory. Once the tests are complete, your results will be instantly emailed to you, allowing you to take prompt action on your health.",
  },
  {
    id: 4,
    pic: HeroImg2,
    title: "Hospice Care",
    content:
      "Hospice care at our facility provides compassionate, end-of-life support for the elderly and physically vulnerable. Our dedicated team ensures that each resident receives personalized care that prioritizes comfort, dignity, and quality of life. We offer pain and symptom management, emotional and spiritual support, and assistance with daily activities in a peaceful, home-like environment. Our goal is to create a comforting space where residents and their families can find solace and support during this critical time.",
  },
  {
    id: 5,
    pic: HeroImg3,
    title: "Counselling & Therapy",
    content:
      "Emotional and mental well-being is just as important as physical health. Our counselling and therapy services provide support for various mental health challenges, offering a safe and confidential space to explore your thoughts and feelings. Our professional therapists are here to help you navigate life's difficulties and improve your overall well-being.",
  },
  {
    id: 6,
    pic: HeroImg1,
    title: "Assisted Living",
    content:
      "Our assisted living services provide a supportive environment for those who need a little extra help with daily activities. Whether due to Alzheimer’s disease, dementia, or other memory impairments. From personal care and household chores to meal preparation and companionship, our dedicated caregivers ensure you maintain your independence while receiving the assistance you need.",
  },
  {
    id: 7,
    pic: HeroImg2,
    title: "Fitness & Nutrition",
    content:
      "Achieve your health goals with our personalized fitness and nutrition programs. Our experts will develop tailored exercise routines and dietary plans that fit your lifestyle and health needs. Whether you aim is for therapeutic diets, to improve mobility, manage weight, or enhance overall well-being, we are here to guide you every step of the way.",
  },
  {
    id: 8,
    pic: HeroImg3,
    title: "Physiotherapy",
    content:
      "Regain your strength and mobility with our specialized physical therapy services. Our licensed physical therapists create customized rehabilitation plans designed to meet your specific needs and goals. Through targeted exercises and therapies, we help you recover from injuries, surgeries, or manage chronic conditions effectively.",
  },
];
