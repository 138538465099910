import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import HeroImg1 from "../../assets/heroimg-1.jpg";
import HeroImg2 from "../../assets/heroimg-2.jpg";
import HeroImg3 from "../../assets/heroimg-3.jpg";
import styles from "./Hero.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { FaArrowCircleRight } from "react-icons/fa";


// import required modules
import { Navigation, Autoplay, Pagination } from "swiper/modules";
import "animate.css";
import { useInView } from "../../hooks";

const Hero = () => {
  const { ref, isInView } = useInView({ threshold: 0.1 });
  return (
    <Box sx={{ width: "100%" }} ref={ref}>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          <Grid container className={styles.imageContainer}>
            <img
              src={HeroImg3}
              alt="Background"
              className={styles.backgroundImage}
            />
            <Grid className={styles.overlay}>
              <Stack
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className={`animate__animated ${
                  isInView ? "animate__flipOutY" : ""
                }`}
              >
                <Grid sx={{paddingBottom:"1rem"}}>
                  <Typography
                    sx={{
                      backgroundColor: "primary.main",
                      padding: "0.6rem 1.2rem 0.6rem 1.2rem",
                      fontSize: {lg:"1em", md:"1em", sm:"1em", xs:"0.6em"},
                      borderRadius: "20px",
                      textTransform: "uppercase",
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    Welcome to Kent and Partners Care Home
                  </Typography>
                </Grid>
                <Typography
                  sx={{
                    fontSize: { lg: "3em", md: "2.5em", sm: "2em", xs: "1.5em" },
                    textAlign: "center",
                    width: { lg: "60%", md: "80%", sm: "80%", xs: "80%" },
                    fontFamily: "Candal, sans-serif;",
                    fontWeight: "400",
                    fontStyle: "normal",
                    paddingBottom:"1rem"
                  }}
                >
                  Dignity, <span style={{ color: "#26AAE0" }}>Respect</span> and
                  Loving Care For Elders
                </Typography>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: "1.2em",
                  }}
                >
                  Your Loved Ones Deserve the Best, We Provide It.
                </Typography>
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    paddingTop: "2rem",
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      borderRadius: "20px",
                      width: "150px",
                      color: "background.paper",
                      marginRight: "1rem",
                      "&:hover": {
                        backgroundColor: "secondary.main",
                      },
                    }}
                    endIcon={<FaArrowCircleRight color="#fffafa" />}
                  >
                    Read More
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      borderRadius: "20px",
                      width: "150px",
                      color: "background.paper",
                      backgroundColor: "secondary.main",
                      "&:hover": {
                        backgroundColor: "primary.main",
                      },
                    }}
                    endIcon={<FaArrowCircleRight color="#fffafa" />}
                  >
                    Contact Us
                  </Button>
                </Grid>
              </Stack>
            </Grid>
          </Grid>
        </SwiperSlide>
        <SwiperSlide>
          <Grid container className={styles.imageContainer}>
            <img
              src={HeroImg2}
              alt="Background"
              className={styles.backgroundImage}
            />
            <Grid className={styles.overlay}>
              <Stack
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className={`animate__animated ${
                  isInView ? "animate__flipOutY" : ""
                }`}
              >
                <Grid sx={{ paddingBottom: "1rem" }}>
                  <Typography
                    sx={{
                      backgroundColor: "primary.main",
                      padding: "0.6rem 1.2rem 0.6rem 1.2rem",
                      fontSize: {lg:"1em", md:"1em", sm:"1em", xs:"0.6em"},
                      borderRadius: "20px",
                      textTransform: "uppercase",
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    Welcome to Kent and Partners Care Home
                  </Typography>
                </Grid>
                <Typography
                  sx={{
                    fontSize: { lg: "3em", md: "2.5em", sm: "2em", xs: "1.5em" },
                    textAlign: "center",
                    width: { lg: "60%", md: "80%", sm: "80%", xs: "80%" },
                    fontFamily: "Candal, sans-serif;",
                    fontWeight: "400",
                    fontStyle: "normal",
                    paddingBottom:"1rem"
                  }}
                >
                  Life-Enriching Care for the{" "}
                  <span style={{ color: "#26AAE0" }}>Elderly</span>
                </Typography>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: "1.2em",
                  }}
                >
                  Experience Peace of Mind, Choose Our Care.
                </Typography>
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    paddingTop: "2rem",
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      borderRadius: "20px",
                      width: "150px",
                      color: "background.paper",
                      marginRight: "1rem",
                      "&:hover": {
                        backgroundColor: "secondary.main",
                      },
                    }}
                    endIcon={<FaArrowCircleRight color="#fffafa" />}
                  >
                    Read More
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      borderRadius: "20px",
                      width: "150px",
                      color: "background.paper",
                      backgroundColor: "secondary.main",
                      "&:hover": {
                        backgroundColor: "primary.main",
                      },
                    }}
                    endIcon={<FaArrowCircleRight color="#fffafa" />}
                  >
                    Contact Us
                  </Button>
                </Grid>
              </Stack>
            </Grid>
          </Grid>
        </SwiperSlide>
        <SwiperSlide>
          <Grid container className={styles.imageContainer}>
            <img
              src={HeroImg1}
              alt="Background"
              className={styles.backgroundImage}
            />
            <Grid className={styles.overlay}>
              <Stack
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className={`animate__animated ${
                  isInView ? "animate__flipOutY" : ""
                }`}
              >
                <Grid sx={{ paddingBottom: "1rem" }}>
                  <Typography
                    sx={{
                      backgroundColor: "primary.main",
                      padding: "0.6rem 1.2rem 0.6rem 1.2rem",
                      fontSize: {lg:"1em", md:"1em", sm:"1em", xs:"0.6em"},
                      borderRadius: "20px",
                      textTransform: "uppercase",
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    Welcome to Kent and Partners Care Home
                  </Typography>
                </Grid>
                <Typography
                  sx={{
                    fontSize: { lg: "3em", md: "2.5em", sm: "2em", xs: "1.5em" },
                    textAlign: "center",
                    width: { lg: "60%", md: "80%", sm: "80%", xs: "80%" },
                    fontFamily: "Candal, sans-serif;",
                    fontWeight: "400",
                    fontStyle: "normal",
                    paddingBottom:"1rem"
                  }}
                >
                  Where <span style={{ color: "#26AAE0" }}>Compassion</span>{" "}
                  Meets Comfort
                </Typography>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: "1.2em",
                  }}
                >
                  We are fully dedicated to the joy and comfort of elders.
                </Typography>
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    paddingTop: "2rem",
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      borderRadius: "20px",
                      width: "150px",
                      color: "background.paper",
                      marginRight: "1rem",
                      "&:hover": {
                        backgroundColor: "secondary.main",
                      },
                    }}
                    endIcon={<FaArrowCircleRight color="#fffafa" />}
                  >
                    Read More
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      borderRadius: "20px",
                      width: "150px",
                      color: "background.paper",
                      backgroundColor: "secondary.main",
                      "&:hover": {
                        backgroundColor: "primary.main",
                      },
                    }}
                    endIcon={<FaArrowCircleRight color="#fffafa" />}
                  >
                    Contact Us
                  </Button>
                </Grid>
              </Stack>
            </Grid>
          </Grid>
        </SwiperSlide>
      </Swiper>
    </Box>
  );
};

export default Hero;
