import { Box, ThemeProvider, CssBaseline } from "@mui/material";
import { theme } from "./helpers/theme";
import HomePage from "./components/HomePage";
function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Box sx={{ width: "100%" }}>
          <CssBaseline />
          <HomePage/>
        </Box>
      </ThemeProvider>
    </div>
  );
}

export default App;
