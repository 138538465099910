import { Box, Card, Grid, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import { whyChooseUseData } from "../../../helpers/whychooseusData";

const WhyChooseUs = () => {
  return (
    <Box sx={{ width: "100%" }}>
      <Stack>
        <Grid container sx={{ display: "flex", justifyContent: "center" }}>
          <Typography
            sx={{ fontSize: "1.3em", color: "primary.main", fontWeight: "500" }}
          >
            WHY CHOOSE US
          </Typography>
        </Grid>

        <Grid
          container
          sx={{ paddingTop: "1rem", display: "flex", justifyContent: "center" }}
        >
          <Typography
            sx={{
              fontSize: { lg: "2em", md: "1.7em", sm: "1.4em", xs: "1em" },
              textAlign: "center",
              width: { lg: "80%", md: "80%", sm: "100%", xs: "100%" },
              fontFamily: "Candal, sans-serif;",
              fontWeight: "400",
              fontStyle: "normal",
              color: "secondary.main",
            }}
          >
            Whether you require{" "}
            <span style={{ color: "#26AAE0" }}>24-hour live-in care</span> or{" "}
            <span style={{ color: "#26AAE0" }}>daily visiting care</span> we
            ensure we <span>effectively manage</span> and pair you up with the{" "}
            <span style={{ color: "#26AAE0" }}>best caregiver </span>
            for your distinct needs and situation.
          </Typography>
        </Grid>

        <Grid
          container
          columnSpacing={{lg:4, md:4, sm:0, xs:0}}
          rowSpacing={4}
          sx={{
            width: "100%",
            paddingTop: "3rem",
            display: "flex",
            alignItems: "center",
            justifyContent:"center"
          }}
        >
          {whyChooseUseData.map((choose) => {
            const { id, pic, title, desc } = choose;
            return (
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={4}
                key={id}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Card
                  sx={{
                    padding: { lg: "2rem", md: "2rem", sm: "1rem", xs: "1rem" },
                    display: "flex",
                    justifyContent: "center",
                    borderRadius: "20px",
                    height: "600px",
                    "&:hover": {
                      boxShadow: "-8px 15px 45px 0px rgba(139,211,227,0.20)",
                    },
                  }}
                >
                  <Stack>
                    <Grid
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        paddingBottom: "2rem",
                      }}
                    >
                      <img
                        src={pic}
                        alt="service-icon"
                        style={{ width: 50, height: 50 }}
                      />
                    </Grid>
                    <Grid>
                      <Typography
                        sx={{
                          fontSize: "1em",
                          fontWeight: "550",
                          color: "secondary.main",
                          textAlign: "center",
                        }}
                      >
                        {title}
                      </Typography>
                    </Grid>
                    <Grid sx={{ paddingTop: "1rem" }}>
                      <Typography
                        sx={{
                          fontSize: "1.3em",
                          textAlign: "center",
                          color: "black.main",
                        }}
                      >
                        {desc}
                      </Typography>
                    </Grid>
                  </Stack>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Stack>
    </Box>
  );
};

export default WhyChooseUs;
