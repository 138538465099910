import { Box, Button, Grid, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import ForwardOutlinedIcon from "@mui/icons-material/ForwardOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import XIcon from "@mui/icons-material/X";
import Logo from "../../../assets/eldercare.png";

const Footer = () => {
  const [email, setEmail] = useState("");

  console.log("email", email);
  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          backgroundColor: "secondary.main",
          padding: { lg: "3rem", md: "3rem", sm: "2rem", xs: "1.5rem" },
        }}
      >
        <Grid container columnSpacing={3} rowSpacing={3} sx={{ width: "100%" }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={4}
            sx={{
              paddingBottom: { lg: "0", md: "2rem", sm: "2rem", xs: "2rem" },
            }}
          >
            <Stack>
              <Grid
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={Logo}
                  alt="Logo"
                  width={50}
                  height={50}
                  style={{ margin: "0 0.5rem -0.5rem 0" }}
                />
                <Typography
                  sx={{
                    fontSize: "1em",
                    textAlign: "left",
                    fontFamily: "Candal, sans-serif;",
                    fontWeight: "500",
                    fontStyle: "normal",
                    color: "primary.main",
                  }}
                >
                  KENT & <span style={{ color: "#fffafa" }}>PARTNERS CARE</span>
                </Typography>
              </Grid>
              <Grid sx={{ paddingTop: "3rem" }}>
                <Typography
                  sx={{
                    fontSize: "1em",
                    color: "background.paper",
                    textAlign: "left",
                    width: { lg: "90%", md: "90%", sm: "90%", xs: "100%" },
                  }}
                >
                  We are compassionate about delivering exceptional service. We
                  are dedicated to enhancing the quality of life for the elderly
                  and mobility-impaired in our community
                </Typography>
              </Grid>
              <Grid
                container
                sx={{
                  display: "flex",
                  alignItems: "center",
                  paddingTop: "1rem",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Typography
                    sx={{ color: "background.paper", fontSize: "1em" }}
                  >
                    <PhoneOutlinedIcon
                      sx={{
                        color: "background.paper",
                        backgroundColor: "primary.main",
                        borderRadius: "50%",
                        padding: "0.5rem",
                        fontSize: 30,
                        margin: "0 0.8rem -0.5rem 0",
                      }}
                    />
                    09052781116
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                sx={{
                  display: "flex",
                  alignItems: "center",
                  paddingTop: "1rem",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Typography
                    sx={{ color: "background.paper", fontSize: "0.9em" }}
                  >
                    <RoomOutlinedIcon
                      sx={{
                        color: "background.paper",
                        backgroundColor: "primary.main",
                        borderRadius: "50%",
                        padding: "0.5rem",
                        fontSize: 30,
                        margin: "0 0.8rem -0.5rem 0",
                      }}
                    />
                    FLAT 4, BLOCK B30 ZONE 3, GAMES VILLAGE, ABUJA.
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                sx={{
                  display: "flex",
                  alignItems: "center",
                  paddingTop: "1rem",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Typography
                    sx={{ color: "background.paper", fontSize: "1em" }}
                  >
                    <EmailOutlinedIcon
                      sx={{
                        color: "background.paper",
                        backgroundColor: "primary.main",
                        borderRadius: "50%",
                        padding: "0.5rem",
                        fontSize: 30,
                        margin: "0 0.8rem -0.5rem 0",
                      }}
                    />
                    support@kentandpartnerscare.com.ng
                  </Typography>
                </Grid>
              </Grid>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={2.5}>
            <Stack>
              <Grid>
                <Typography
                  sx={{
                    fontFamily: "Candal, sans-serif;",
                    fontWeight: "400",
                    fontStyle: "normal",
                    color: "background.paper",
                  }}
                >
                  Quick Links
                </Typography>
              </Grid>
              <Grid
                container
                sx={{
                  display: "flex",
                  alignItems: "center",
                  paddingTop: "3rem",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{
                    transition: "transform 0.3s ease",
                    "&:hover": {
                      transform: "translateX(10px)",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      color: "background.paper",
                      fontSize: "1em",
                      "&:hover": {
                        color: "primary.main",
                      },
                    }}
                  >
                    <ForwardOutlinedIcon
                      sx={{
                        color: "primary.main",
                        fontSize: 20,
                        margin: "0 0.8rem -0.2rem 0",
                      }}
                    />
                    About Us
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                sx={{
                  display: "flex",
                  alignItems: "center",
                  paddingTop: "1rem",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{
                    transition: "transform 0.3s ease",
                    "&:hover": {
                      transform: "translateX(10px)",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      color: "background.paper",
                      fontSize: "1em",
                      "&:hover": {
                        color: "primary.main",
                      },
                    }}
                  >
                    <ForwardOutlinedIcon
                      sx={{
                        color: "primary.main",
                        fontSize: 20,
                        margin: "0 0.8rem -0.2rem 0",
                      }}
                    />
                    FAQs
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                sx={{
                  display: "flex",
                  alignItems: "center",
                  paddingTop: "1rem",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{
                    transition: "transform 0.3s ease",
                    "&:hover": {
                      transform: "translateX(10px)",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      color: "background.paper",
                      fontSize: "1em",
                      "&:hover": {
                        color: "primary.main",
                      },
                    }}
                  >
                    <ForwardOutlinedIcon
                      sx={{
                        color: "primary.main",
                        fontSize: 20,
                        margin: "0 0.8rem -0.2rem 0",
                      }}
                    />
                    Careers
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                sx={{
                  display: "flex",
                  alignItems: "center",
                  paddingTop: "1rem",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{
                    transition: "transform 0.3s ease",
                    "&:hover": {
                      transform: "translateX(10px)",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      color: "background.paper",
                      fontSize: "1em",
                      "&:hover": {
                        color: "primary.main",
                      },
                    }}
                  >
                    <ForwardOutlinedIcon
                      sx={{
                        color: "primary.main",
                        fontSize: 20,
                        margin: "0 0.8rem -0.2rem 0",
                      }}
                    />
                    Terms Of Service
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                sx={{
                  display: "flex",
                  alignItems: "center",
                  paddingTop: "1rem",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{
                    transition: "transform 0.3s ease",
                    "&:hover": {
                      transform: "translateX(10px)",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      color: "background.paper",
                      fontSize: "1em",
                      "&:hover": {
                        color: "primary.main",
                      },
                    }}
                  >
                    <ForwardOutlinedIcon
                      sx={{
                        color: "primary.main",
                        fontSize: 20,
                        margin: "0 0.8rem -0.2rem 0",
                      }}
                    />
                    Privacy Policy
                  </Typography>
                </Grid>
              </Grid>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={2.5}>
            <Stack>
              <Grid>
                <Typography
                  sx={{
                    fontFamily: "Candal, sans-serif;",
                    fontWeight: "400",
                    fontStyle: "normal",
                    color: "background.paper",
                  }}
                >
                  Services
                </Typography>
              </Grid>
              <Grid
                container
                sx={{
                  display: "flex",
                  alignItems: "center",
                  paddingTop: "3rem",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{
                    transition: "transform 0.3s ease",
                    "&:hover": {
                      transform: "translateX(10px)",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      color: "background.paper",
                      fontSize: "1em",
                      "&:hover": {
                        color: "primary.main",
                      },
                    }}
                  >
                    <ForwardOutlinedIcon
                      sx={{
                        color: "primary.main",
                        fontSize: 20,
                        margin: "0 0.8rem -0.2rem 0",
                      }}
                    />
                    Doctor Visits
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                sx={{
                  display: "flex",
                  alignItems: "center",
                  paddingTop: "1rem",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{
                    transition: "transform 0.3s ease",
                    "&:hover": {
                      transform: "translateX(10px)",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      color: "background.paper",
                      fontSize: "1em",
                      "&:hover": {
                        color: "primary.main",
                      },
                    }}
                  >
                    <ForwardOutlinedIcon
                      sx={{
                        color: "primary.main",
                        fontSize: 20,
                        margin: "0 0.8rem -0.2rem 0",
                      }}
                    />
                    Nursing Care
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                sx={{
                  display: "flex",
                  alignItems: "center",
                  paddingTop: "1rem",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{
                    transition: "transform 0.3s ease",
                    "&:hover": {
                      transform: "translateX(10px)",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      color: "background.paper",
                      fontSize: "1em",
                      "&:hover": {
                        color: "primary.main",
                      },
                    }}
                  >
                    <ForwardOutlinedIcon
                      sx={{
                        color: "primary.main",
                        fontSize: 20,
                        margin: "0 0.8rem -0.2rem 0",
                      }}
                    />
                    Pharmaceuticals
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                sx={{
                  display: "flex",
                  alignItems: "center",
                  paddingTop: "1rem",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{
                    transition: "transform 0.3s ease",
                    "&:hover": {
                      transform: "translateX(10px)",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      color: "background.paper",
                      fontSize: "1em",
                      "&:hover": {
                        color: "primary.main",
                      },
                    }}
                  >
                    <ForwardOutlinedIcon
                      sx={{
                        color: "primary.main",
                        fontSize: 20,
                        margin: "0 0.8rem -0.2rem 0",
                      }}
                    />
                    Diagnostics
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                sx={{
                  display: "flex",
                  alignItems: "center",
                  paddingTop: "1rem",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{
                    transition: "transform 0.3s ease",
                    "&:hover": {
                      transform: "translateX(10px)",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      color: "background.paper",
                      fontSize: "1em",
                      "&:hover": {
                        color: "primary.main",
                      },
                    }}
                  >
                    <ForwardOutlinedIcon
                      sx={{
                        color: "primary.main",
                        fontSize: 20,
                        margin: "0 0.8rem -0.2rem 0",
                      }}
                    />
                    Hospice Care
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                sx={{
                  display: "flex",
                  alignItems: "center",
                  paddingTop: "1rem",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{
                    transition: "transform 0.3s ease",
                    "&:hover": {
                      transform: "translateX(10px)",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      color: "background.paper",
                      fontSize: "1em",
                      "&:hover": {
                        color: "primary.main",
                      },
                    }}
                  >
                    <ForwardOutlinedIcon
                      sx={{
                        color: "primary.main",
                        fontSize: 20,
                        margin: "0 0.8rem -0.2rem 0",
                      }}
                    />
                    Counseling & Therapy
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                sx={{
                  display: "flex",
                  alignItems: "center",
                  paddingTop: "1rem",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{
                    transition: "transform 0.3s ease",
                    "&:hover": {
                      transform: "translateX(10px)",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      color: "background.paper",
                      fontSize: "1em",
                      "&:hover": {
                        color: "primary.main",
                      },
                    }}
                  >
                    <ForwardOutlinedIcon
                      sx={{
                        color: "primary.main",
                        fontSize: 20,
                        margin: "0 0.8rem -0.2rem 0",
                      }}
                    />
                    Assisted Living
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                sx={{
                  display: "flex",
                  alignItems: "center",
                  paddingTop: "1rem",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{
                    transition: "transform 0.3s ease",
                    "&:hover": {
                      transform: "translateX(10px)",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      color: "background.paper",
                      fontSize: "1em",
                      "&:hover": {
                        color: "primary.main",
                      },
                    }}
                  >
                    <ForwardOutlinedIcon
                      sx={{
                        color: "primary.main",
                        fontSize: 20,
                        margin: "0 0.8rem -0.2rem 0",
                      }}
                    />
                    Fitness & Nutrition
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                sx={{
                  display: "flex",
                  alignItems: "center",
                  paddingTop: "1rem",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{
                    transition: "transform 0.3s ease",
                    "&:hover": {
                      transform: "translateX(10px)",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      color: "background.paper",
                      fontSize: "1em",
                      "&:hover": {
                        color: "primary.main",
                      },
                    }}
                  >
                    <ForwardOutlinedIcon
                      sx={{
                        color: "primary.main",
                        fontSize: 20,
                        margin: "0 0.8rem -0.2rem 0",
                      }}
                    />
                    Physiotherapy
                  </Typography>
                </Grid>
              </Grid>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <Stack>
              <Grid>
                <Typography
                  sx={{
                    fontFamily: "Candal, sans-serif;",
                    fontWeight: "400",
                    fontStyle: "normal",
                    color: "background.paper",
                  }}
                >
                  Newsletter
                </Typography>
              </Grid>
              <Grid sx={{ paddingTop: "3rem" }}>
                <Typography
                  sx={{
                    fontSize: "1em",
                    textAlign: "left",
                    color: "background.paper",
                  }}
                >
                  Subscribe Our Newsletter To Get Latest Update And News
                </Typography>
              </Grid>
              <Grid sx={{ paddingTop: "2rem" }}>
                <TextField
                  name="email"
                  type="text"
                  size="medium"
                  placeholder="Enter your email here"
                  onChange={(e) => setEmail(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid sx={{ paddingTop: "2rem" }}>
                <Button
                  sx={{
                    backgroundColor: "primary.main",
                    textTransform: "uppercase",
                    color: "background.paper",
                    borderRadius: "30px",
                    width: "200px",
                  }}
                  endIcon={
                    <SendOutlinedIcon sx={{ color: "background.paper" }} />
                  }
                >
                  Subscribe Now
                </Button>
              </Grid>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Grid
        container
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          backgroundColor: "#2D3555",
          padding: {
            lg: "2rem 3rem 2rem 3rem",
            md: "2rem 3rem 2rem 3rem",
            sm: "2rem 3rem 2rem 3rem",
            xs: "2rem 1rem 2rem 1rem",
          },
        }}
      >
        <Grid
          item
          xs={8}
          sm={8}
          md={6}
          lg={6}
          sx={{ display: "flex", justifyContent: "flex-start" }}
        >
          <Typography
            sx={{
              color: "background.paper",
              fontSize: { lg: "1em", md: "0.7em", sm: "0.7em", xs: "0.5em" },
            }}
          >
            {" "}
            <span style={{ fontSize: "1.3em" }}>©</span> Copyright 2024 | Kent
            and Partners Care. All Rights Reserved.
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          sm={4}
          md={6}
          lg={6}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Typography>
            <FacebookOutlinedIcon
              sx={{
                color: "primary.main",
                fontSize: { lg: 25, md: 15, sm: 15, xs: 15 },
                marginRight: "1rem",
              }}
            />
            <InstagramIcon
              sx={{
                color: "primary.main",
                fontSize: { lg: 25, md: 15, sm: 15, xs: 15 },
                marginRight: "1rem",
              }}
            />
            <LinkedInIcon
              sx={{
                color: "primary.main",
                fontSize: { lg: 25, md: 15, sm: 15, xs: 15 },
                marginRight: "1rem",
              }}
            />
            <XIcon
              sx={{
                color: "primary.main",
                fontSize: { lg: 25, md: 15, sm: 15, xs: 15 },
              }}
            />
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
